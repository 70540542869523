@import "variables";
@import "typography";
@import "nprogress";

html,
body,
ul,
ol {
  margin:  0;
  padding: 0;
}

body {
  overflow-x: hidden;
  will-change: background-color;
  transition: background-color ease 300ms;
}

html,
body {
  background-color: $body-background;
}

html,
body,
#root {
  color: $base-font-color;
  width: 100%;
  min-height: 100vh;
}

#root {
  display: flex;
  background: #fff;
  max-width: $root-max-width;
  box-shadow: $base-box-shadow;
  margin: $root-margin;
}

body *:not(svg) {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

strong {
  font-weight: $font-bold !important;
}

header a {
  text-decoration: unset;
}

@media (max-width: $screen-xl) {
  #root {
    background: #fff;
    transition: margin 0.5s;
    height: auto;
    min-height: unset;
    box-shadow: unset;
    margin: 0 !important;
  }
}
