@import "theme";
@import "typography";

// Colors
$primary:             $brand-primary;
$secondary:           $brand-secondary;
$terciary:            $brand-terciary;
$white-color:         $brand-white;
$dark-color:          $brand-black;
$gray-color:          #5A5A5A;
$divider-color:       #EAEAEA;
$recreational:        #107e22;
$limited:             #515ED3;
$advanced:            #FA9209;
$expert:              #FF1B26;

// Borders
$base-border:     1px solid $terciary;
$base-radius:     0.25rem;

// Body
$body-background: #f0f0f0;
$root-margin: 60px auto 80px;
$root-max-width: 1200px;

// CodeLab
$code-lab-side-drawer-closed-width: 56px;

// Shadows
$base-box-shadow:   rgba(darken($brand-black, 33%), 0.1) 0 0 5px;
$navbar-box-shadow: rgba(darken($brand-black, 20%), 0.2) 0 1.5px 2.5px 0px;

// Media queries breakpoints
$screen-xs:       480px;
$screen-sm:       576px;
$screen-md:       768px;
$screen-lg:       992px;
$screen-xl:       1200px;
$screen-xxl:      1600px;
